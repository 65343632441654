import React, { useCallback } from "react";

const FrameworkInfo = ({ info, framework, setInfo }) => {
  const handleChange = useCallback(
    (e) => {
      const { name, value } = e?.target;
      setInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value,
        framework: framework.toLowerCase(),
      }));

      // console.log("info = ", info);
    },
    [framework, setInfo]
  );

  return (
    <div className="mt-8 w-100">
      <div className="d-flex align-items-start gap-5">
        <div className="w-50">
          <h6 className="mt-4 mb-2">{framework} Info </h6>

          <div className="single-input">
            <div className="flex-fill">
              <p>Category Name</p>
              <input
                className="mb-4 code-input w-100"
                type="text"
                name="title"
                value={info?.title || ""}
                onChange={handleChange}
              />
            </div>
            <div className="flex-fill">
              <p>Description</p>
              <textarea
                className="code-input w-100"
                name="description"
                value={info?.description || ""}
                onChange={handleChange}
                rows={5}
              />
            </div>
          </div>
        </div>

        <div className="w-50">
          <h6 className="mt-4 mb-2">{framework} SEO Info </h6>

          <div className="single-input">
            <div className="flex-fill">
              <p>Title</p>
              <input
                className="mb-4 code-input w-100"
                type="text"
                name="seoTitle"
                value={info?.seoTitle || ""}
                onChange={handleChange}
              />
            </div>
            <div className="flex-fill">
              <p>Description</p>
              <textarea
                className="code-input w-100"
                name="seoDescription"
                value={info?.seoDescription || ""}
                onChange={handleChange}
                rows={5}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameworkInfo;
